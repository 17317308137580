.list-unordered {
  list-style: none;

  &__item {
    position: relative;
    display: flex;
    gap: 1.5rem;

    &::before {
      content: "";
      display: block;
      flex: 0 0 auto;
      width: 1.5rem;
      height: 1px;
      margin-top: 12px;
      background: currentcolor;

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        margin-top: 16px;
      }

      @media screen and (min-width: map-get($breakpoints, "large")) {
        width: 2rem;
        margin-top: 19px;
      }
    }
  }
}
