.video-player-wrapper {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 25rem;
  max-height: 80vh;
}

.video-player {
  @include transition(opacity);

  opacity: 0;
  aspect-ratio: 16/9;
}
