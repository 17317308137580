// helper blocks
.header-wrapper {
  display: flex;
  align-items: center;
}

.header-logo {
  display: inline-flex;
  text-decoration: none;

  svg {
    width: 10.5rem;
    height: auto;

    path,
    rect {
      fill: $color-white;
    }

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      width: 14rem;
    }
  }
}

// main block
.header {
  position: absolute;
  top: 0;
  width: 100%;
  height: var(--header-height);
  background: transparent;
  z-index: 10;

  .row-01 {
    height: 100%;
    margin: 0 auto;
    padding: 0 var(--container-grid-outer-gap);
    background: linear-gradient(180deg, rgb(0 0 0 / 70%) 41.25%, rgb(0 0 0 / 0%) 100%);

    .col-01 {
      @include grid-column(1, -1);

      @media screen and (min-width: map-get($breakpoints, "extra-large")) {
        @include grid-column(2, -2);
      }
    }
  }

  &--dark {
    background: $color-black;
  }

  &--primary {
    background: $color-primary;
  }

  &--no-bg-guard {
    .row-01 {
      background: none;
    }
  }
}
