// helper blocks
.studio-facts-navigation-btn {
  display: flex;
  flex-direction: column;
  background: transparent;
  text-align: left;
  border: none;
  pointer-events: none;

  &__title,
  &__text {
    margin-bottom: 0;
  }

  &__title {
    @include transition(color);

    color: $color-white;
  }

  &__text {
    margin-top: .625rem;
    color: $color-grey-light-1;
  }

  @media screen and (min-width: map-get($breakpoints, "medium-min")) {
    pointer-events: all;
    cursor: pointer;

    &__text {
      margin-top: 0;
    }
  }
}

.row-studio-facts {
  row-gap: var(--spacer-xs);

  .col-studio-facts-content {
    @include grid-column(1, 4, 1, 4, 1, 5, 1, 4);
    @include transition(margin);

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      margin-bottom: 24px;

      .studio-facts-navigation-btn__title {
        @include transition(color);

        color: $color-grey-dark-2;

        &:hover {
          color: $color-white;
        }
      }

      .studio-facts-navigation-btn__text {
        max-height: 0;
        color: $color-white;
        overflow: hidden;
      }

      &--is-active {
        .studio-facts-navigation-btn {
          pointer-events: none;
        }

        .studio-facts-navigation-btn__title {
          color: $color-primary;
        }

        .studio-facts-navigation-btn__text {
          max-height: 100%;
          margin-top: .625rem;
          overflow: visible;
        }
      }

      &--is-active {
        margin-bottom: 36px;
      }
    }

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      &--is-active {
        margin-bottom: 56px;
      }
    }

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      &--is-active {
        margin-bottom: 180px;
      }
    }
  }

  .col-studio-facts-image {
    @include grid-column(4, -1, 4, -1, 7, -1, 5, -1);

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      position: absolute;
      grid-row: 1 / 2;
      overflow: hidden;

      &:not(.col-studio-facts-image--is-active) {
        .studio-facts-images__picture {
          animation: fade-out 350ms cubic-bezier(.165, .84, .44, 1) forwards;
        }
      }

      &--is-active.animate-down {
        .studio-facts-images__picture {
          animation: fade-in-slide-down 500ms cubic-bezier(.165, .84, .44, 1) forwards;
        }
      }

      &--is-active.animate-up {
        .studio-facts-images__picture {
          animation: fade-in-slide-up 500ms cubic-bezier(.165, .84, .44, 1) forwards;
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, "medium-min")) {
    row-gap: unset;
  }
}

// main block
.studio-section {
  .row-04 {
    .col-01 {
      margin-left: calc(var(--container-grid-gap) * -1);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-left: auto;
      }
    }
  }
}
