/* stylelint-disable declaration-block-no-redundant-longhand-properties */
@mixin background-image(
  $imgpath,
  $position: 0 0,
  $background-size: cover,
  $repeat: no-repeat,
  $background-color: #fff) {
  background: {
    image: url($imgpath);
    position: $position;
    repeat: $repeat;
    size: $background-size;
    color: $background-color;
  }
}

@mixin grid-column(
  $small-start,
  $small-end,
  $medium-min-start: null,
  $medium-min-end: null,
  $medium-max-start: null,
  $medium-max-end: null,
  $large-start: null,
  $large-end: null) {
  grid-column-start: $small-start;
  grid-column-end: $small-end;

  @if $medium-min-start and $medium-min-end {
    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      grid-column-start: $medium-min-start;
      grid-column-end: $medium-min-end;
    }
  }

  @if $medium-max-start and $medium-max-end {
    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      grid-column-start: $medium-max-start;
      grid-column-end: $medium-max-end;
    }
  }

  @if $large-start and $large-end {
    @media screen and (min-width: map-get($breakpoints, "large")) {
      grid-column-start: $large-start;
      grid-column-end: $large-end;
    }
  }
}

@mixin line-clamp($line) {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  overflow: hidden;
}

@mixin scale-down {
  &:active {
    transform: scale(.95);
  }
}

// TODO Refactor to allow variable timing and easing curves
@mixin transition($properties...) {
  $transition: ();

  @each $property in $properties {
    /* stylelint-disable-next-line scss/no-global-function-names */
    $transition: append(
      $transition,
      ($property 150ms cubic-bezier(.42, 0, .58, 1)),
      $separator: comma
    );
  }

  transition: $transition;
}
