.link {
  @include transition(color);

  display: inline-flex;
  align-items: center;
  gap: 1rem;
  background: transparent;
  border: none;
  text-decoration: none;
  cursor: pointer;

  svg path {
    @include transition(stroke);
  }

  &--primary {
    color: $color-black;

    svg path {
      @include transition(stroke);

      stroke: $color-black;
    }

    &:hover {
      color: $color-secondary-dark;

      svg path {
        stroke: $color-secondary-dark;
      }
    }
  }

  &--secondary {
    color: $color-secondary;

    /* stylelint-disable-next-line no-descending-specificity */
    svg path {
      stroke: $color-secondary;
    }

    &:hover {
      color: $color-secondary-dark;

      svg path {
        stroke: $color-secondary-dark;
      }
    }
  }

  &--tertiary {
    color: $color-black;

    /* stylelint-disable-next-line no-descending-specificity */
    svg path {
      stroke: $color-black;
    }

    &:hover {
      color: $color-secondary-darker;

      svg path {
        stroke: $color-secondary-darker;
      }
    }
  }

  &--quaternary {
    color: $color-grey-light-1;

    &:hover {
      color: $color-secondary;
    }
  }

  &--has-underline {
    text-decoration: underline;
  }

  &--icon-small {
    svg {
      width: 1.5rem;
    }
  }
}
