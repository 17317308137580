// helper blocks
.main-teaser-image {
  width: 100%;
  height: 100%;
  max-height: 80vh;
  margin-left: auto;
  object-fit: cover;
}

.main-teaser-actions {
  margin-top: var(--spacer-m);
}

// main block
.main-teaser {
  padding-bottom: var(--spacer-xl);

  &--extra-top-spacing {
    padding-top: var(--spacer-xl);
  }

  &--extra-bottom-spacing {
    padding-bottom: calc(3rem + var(--spacer-xl));

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      padding-bottom: calc(6rem + var(--spacer-xl));
    }
  }

  &--extra-bottom-spacing-small {
    padding-bottom: 9rem;
  }

  &--negative-top-margin {
    margin-top: -3rem;

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      margin-top: -6rem;
    }
  }
}
