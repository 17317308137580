:root {
  --container-max-width: 105rem; // 1680px
  --container-wide-max-width: 120rem; // 1920px
  --container-grip-template-cols: repeat(6, 1fr);
  --container-grid-gap: 15px;
  --container-grid-outer-gap: 18px;
  --container-grid-outer-padding: 0;

  @media screen and (min-width: map-get($breakpoints, "medium-min")) {
    --container-grip-template-cols: repeat(12, 1fr);
    --container-grid-gap: 30px;
    --container-grid-outer-gap: 40px;
  }

  @media screen and (min-width: map-get($breakpoints, "large")) {
    --container-grid-gap: 30px;
    --container-grid-outer-gap: 75px;
  }

  @media screen and (min-width: map-get($breakpoints, "extra-large")) {
    --container-grid-outer-gap: auto;
    --container-grid-outer-padding: 0 75px;
  }

  @media screen and (min-width: map-get($breakpoints, "ultra-large")) {
    --container-grid-outer-padding: 0;
  }
}

.container {
  display: grid;
  grid-template-columns: var(--container-grip-template-cols);
  column-gap: var(--container-grid-gap);
  grid-auto-flow: dense;
  max-width: var(--container-max-width);
  margin-left: var(--container-grid-outer-gap);
  margin-right: var(--container-grid-outer-gap);
  padding: var(--container-grid-outer-padding);

  &--wide {
    max-width: var(--container-wide-max-width);
    padding: 0;
  }
}

.row {
  position: relative;

  &--align-v-center {
    align-items: center;
  }

  &--negative-margin-top {
    margin-top: -3rem;

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      margin-top: -4rem;
    }

    @media screen and (min-width: map-get($breakpoints, "large")) {
      margin-top: -6rem;
    }
  }
}

.row-content {
  .col-01 {
    @include grid-column(2, -1, 4, -1, null, null, 5, -1);
  }

  .col-02 {
    @include grid-column(1, -1, 1, 4, null, null, 1, 5);
  }
}

.row-content-small-aside {
  .col-01 {
    @include grid-column(2, -1, 4, -1, null, null, 5, -1);
  }

  .col-02 {
    @include grid-column(2, -1, 1, 4, null, null, 1, 4);
  }
}

.row-content-small {
  .col-01 {
    @include grid-column(2, -1, 4, 9, null, null, 5, 9);
  }
}

.row-content-extended-mobile-full {
  .col-01 {
    @include grid-column(1, -1, 4, -1);
  }
}

.row-content-mobile-full {
  .col-01 {
    @include grid-column(1, -1, 4, -1, null, null, 5, -1);
  }

  .col-02 {
    @include grid-column(1, -1, 1, 4, null, null, 1, 5);
  }
}

.row-media {
  .col-01 {
    @include grid-column(2, -1, 3, -1, null, null, 4, -1);
  }
}

.row-media-mobile-full {
  .col-01 {
    @include grid-column(1, -1, 3, -1, null, null, 4, -1);
  }
}

.row-extended-mobile-full {
  .col-01 {
    @include grid-column(1, -1, 3, -1);
  }
}

.row-title {
  .col-01 {
    @include grid-column(1, -1, 4, -1, null, null, 5, -1);
  }

  .col-02 {
    @include grid-column(1, -1, 1, 4, null, null, 1, 5);
  }
}

.sub-row {
  margin: 0 auto;
}

.col {
  &--full-width {
    @include grid-column(1, -1);
  }

  &--no-margin {
    margin: 0 calc(var(--container-grid-outer-gap) * -1);

    @media screen and (min-width: map-get($breakpoints, "extra-large")) {
      margin: unset;
    }
  }

  &--no-margin-right {
    max-width: var(--container-wide-max-width);
    margin-right: calc(var(--container-grid-outer-gap) * -1);

    @media screen and (min-width: map-get($breakpoints, "extra-large")) {
      margin-right: calc((100vw - 130%) / -2);
    }
  }

  &--no-margin-left {
    max-width: var(--container-wide-max-width);
    margin-left: calc(var(--container-grid-outer-gap) * -1);

    @media screen and (min-width: map-get($breakpoints, "extra-large")) {
      margin-left: calc((100vw - 130%) / -2);
    }
  }
}
