.btn {
  @include transition(background);
  @include transition(border);
  @include transition(box-shadow);

  display: inline-block;
  height: 3.125rem;
  padding: .8rem 2rem;
  border-radius: 5.625rem;
  font-family: $text-font-stack-primary;
  font-size: 1rem;
  text-align: center;
  color: $color-black;
  letter-spacing: inherit;
  border: 3px solid transparent;
  text-decoration: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media screen and (min-width: map-get($breakpoints, "medium-max")) {
    height: 4.875rem;
    min-width: 14.375rem;
    padding: 1.625rem 2.5rem;
    font-size: 1.125rem;
  }

  &--primary {
    background: $color-secondary;
    box-shadow: $btn-shadow;

    &:hover {
      background: $color-secondary-dark;
      box-shadow: $btn-shadow-hover;
    }

    &:disabled {
      color: $color-grey-light-1;
      background: $color-grey-light-2;
      box-shadow: none;
      cursor: default;
    }
  }

  &--secondary {
    background: transparent;
    border: 3px solid $color-secondary;

    &:hover {
      border-color: $color-secondary-dark;
      background: $color-secondary-dark;
    }

    &:disabled {
      color: $color-grey-light-1;
      border-color: $color-grey-light-2;
      cursor: default;
    }
  }

  &--small {
    height: 3.125rem;
    padding: .75rem 1.625rem;
    font-size: 1rem;
  }
}

.btn-chat {
  // Temp disable until Teams chat integration
  pointer-events: none;

  // Temp end
  height: 4.5rem;
  background: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &__operator {
    position: relative;
    height: 100%;
  }

  &__picture {
    height: 100%;
  }

  &__image {
    height: 100%;
    border-radius: 50%;
  }

  &__status {
    // Temp disable until Teams chat integration
    display: none;

    // Temp end
    position: absolute;
    left: -.125rem;
    bottom: .375rem;
    height: .75rem;
    width: .75rem;
    background: $color-white;
    border-radius: 50%;
    box-shadow: 0 0 12px 0 rgb(0 0 0 / 35%);

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      left: .1875rem;
    }

    &--is-online {
      background: $color-online;
    }
  }

  &--extended {
    @include transition(background);

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    min-width: 18.5rem;
    padding: 0 0 0 2rem;
    background: $color-secondary;
    border-radius: 5.625rem;
    font-family: $text-font-stack-primary;
    font-size: 1rem;
    color: $color-black;
    line-height: 1.5rem;

    &:hover {
      background: $color-secondary-dark;
    }
  }

  @media screen and (min-width: map-get($breakpoints, "medium-max")) {
    height: 8.75rem;

    &--extended {
      height: 5.5rem;
      padding: 0 0 0 2.5rem;
      font-size: 1.125rem;
      line-height: 1.625rem;
    }
  }
}

.btn-link {
  @include transition(color);

  background: transparent;
  border: none;
  font-size: 1.125rem; // 18px
  line-height: 1.625rem; // 26px
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &--primary {
    color: $color-black;

    &:hover {
      color: $color-secondary;
    }
  }

  &--secondary {
    color: $color-secondary;

    &:hover {
      color: $color-secondary-dark;
    }
  }

  &--tertiary {
    color: $color-black;

    &:hover {
      color: $color-secondary-darker;
    }
  }

  @media screen and (min-width: map-get($breakpoints, "medium-max")) {
    font-size: 2rem; // 32px
    line-height: 2.5rem; // 40px
  }
}
