@keyframes fade-in-slide-up {
  0% {
    opacity: 0;
    transform: translate3d(0, 150px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fade-in-slide-down {
  0% {
    opacity: 0;
    transform: translate3d(0, -150px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    transform: translate3d(0, -100px, 0);
  }

  100% {
    opacity: 0;
  }
}
