// main block
.cases-section {
  .row-03, .row-04, .row-05, .row-06  {
    .col-01 {
      @include grid-column(1, -1, 1, 7);

      margin-bottom: var(--spacer-l);

      @media screen and (min-width: map-get($breakpoints, 'medium-min')) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      @include grid-column(1, -1, 7, -1);
    }
  }
}
