// main block
.not-found {
  .row-01 {
    .col-01 {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .row-02 {
    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      margin-top: -9rem;
    }

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      margin-top: -17rem;
    }
  }

  &__background {
    font-family: $text-font-stack-heading;
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: .125rem;
    -webkit-text-stroke-color: $color-black;
    font-size: 8rem;
    letter-spacing: -.04em;

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      font-size: 17rem;
    }

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      font-size: 25rem;
    }

    @media screen and (min-width: map-get($breakpoints, "medium-max")) {
      font-size: 31rem;
    }
  }
}
