:root {
  --footer-row-space: 4.5rem;
  --footer-col-space: 2rem;

  // css var with #{} due to issue with postcss-calc https://github.com/postcss/postcss-calc/issues/77
  --footer-item-space: 2rem;
}

// helper blocks
.footer-back-to-top {
  position: absolute;
  top: 1.875rem;
  right: 2.5rem;
  transform: rotate(270deg);
  transform-origin: right;

  &__link {
    @include transition(color);

    margin-right: .5rem;
    color: $color-secondary;
    text-decoration: none;

    &:hover {
      color: $color-secondary-dark;
    }
  }

  svg {
    width: 12.5rem;
  }

  @media screen and (min-width: map-get($breakpoints, "medium-max")) {
    top: 9.5rem;
    right: 2.5rem;
  }
}

// main block
.footer {
  position: relative;
  padding: var(--spacer-l) 0;
  background: $color-black;

  &__logo {
    svg {
      width: 11.5rem;
      height: auto;

      path,
      rect {
        fill: $color-grey-dark-2;
      }
    }
  }

  &__link {
    @include transition(color);

    display: inline-block;
    text-decoration: none;

    &:hover {
      color: $color-grey-light-2;
    }
  }

  &__link--icon {
    svg {
      width: 1.8125rem;
      height: auto;

      path {
        @include transition(fill);
      }

      &:hover {
        path {
          fill: $color-grey-light-2;
        }
      }

      @media screen and (min-width: map-get($breakpoints, "large")) {
        width: 2.25rem;
      }
    }
  }

  .row-01 {
    margin-bottom: var(--footer-row-space);

    .col-01 {
      @include grid-column(1, -1, 1, 5);

      margin-bottom: var(--footer-col-space);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      @include grid-column(1, -1, 5, 9);

      margin-bottom: var(--footer-col-space);

      .footer {
        &__address {
          margin-bottom: var(--footer-col-space);
        }
      }

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-03 {
      @include grid-column(1, -1, 9, -1);
    }

    @media screen and (min-width: map-get($breakpoints, "medium-min")) {
      margin-bottom: 10rem;
    }
  }

  .row-02 {
    // margin-bottom: var(--footer-row-space);

    .col-01 {
      @include grid-column(1, -1, 5, -1);

      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: var(--footer-col-space);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        justify-content: flex-start;
        gap: 2.625rem;
        margin-bottom: 0;
      }
    }

    .col-02 {
      @include grid-column(1, -1, 1, 5);

      display: flex;
      align-items: center;
    }
  }

  @media screen and (min-width: map-get($breakpoints, "medium-max")) {
    padding-top: 7rem;
  }
}
