// main block
.main-hero {
  padding-bottom: var(--spacer-xl);

  &--dark {
    background: $color-grey-dark-1;
  }

  &__row-media {
    .col-01 {
      @include grid-column(1, -1);
    }

    .main-hero-media {
      object-fit: cover;
      width: 100%;
      height: 100%;
      min-height: 25rem;
      max-height: 80vh;
    }
  }

  &__row-title {
    .col-01 {
      @include grid-column(1, -1, 4, -1, null, null, 5, -1);

      margin: -4.5rem 0 0;

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin: -4rem 0 0;
      }

      @media screen and (min-width: map-get($breakpoints, "medium-max")) {
        margin: -6rem 0 0;
      }

      @media screen and (min-width: map-get($breakpoints, "large")) {
        margin: -8rem 0 0;
      }
    }
  }
}
