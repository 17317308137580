// main block
.impressions-section {
  .row-02 {
    margin-bottom: var(--spacer-l);

    .col-01 {
      @include grid-column(1, 6, 1, 9);

      margin-bottom: var(--spacer-l);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      @include grid-column(4, -1, 9, -1);
    }
  }

  .row-03 {
    margin-bottom: var(--spacer-l);

    .col-01 {
      @include grid-column(1, 3, 1, 4);
    }

    .col-02 {
      @include grid-column(3, -1, 5, -1);
    }
  }

  .row-04 {
    margin-bottom: var(--spacer-l);

    .col-01 {
      @include grid-column(1, 4, 1, 7);

      margin-bottom: var(--spacer-l);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      @include grid-column(2, -1, 7, -1);
    }
  }

  .row-05 {
    margin-bottom: var(--spacer-l);

    .col-01 {
      @include grid-column(1, 6, 1, 9);

      margin-bottom: var(--spacer-l);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      @include grid-column(3, -1, 9, -1);
    }
  }

  .row-06 {
    margin-bottom: var(--spacer-l);

    .col-01 {
      @include grid-column(1, 4, 1, 5);

      margin-bottom: var(--spacer-l);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      @include grid-column(2, -1, 5, -1);
    }
  }

  .row-07 {
    margin-bottom: var(--spacer-l);

    .col-01 {
      @include grid-column(1, 5, 1, 9);

      margin-bottom: var(--spacer-l);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      @include grid-column(4, -1, 10, -1);
    }
  }

  .row-08 {
    margin-bottom: var(--spacer-l);

    .col-01 {
      @include grid-column(1, 4, 1, 7);

      margin-bottom: var(--spacer-l);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      @include grid-column(2, -1, 7, -1);
    }
  }

  .row-09 {
    margin-bottom: var(--spacer-l);

    .col-01 {
      @include grid-column(1, 3, 1, 4);

      margin-bottom: var(--spacer-l);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      @include grid-column(2, -1, 5, -1);
    }
  }


  .row-10 {
    margin-bottom: var(--spacer-l);

    .col-01 {
      @include grid-column(1, 5, 1, 9);

      margin-bottom: var(--spacer-l);

      @media screen and (min-width: map-get($breakpoints, "medium-min")) {
        margin-bottom: 0;
      }
    }

    .col-02 {
      @include grid-column(4, -1, 10, -1);
    }
  }
}
