@font-face {
  font-family: "ABCWhyte Bold";
  src:
    url("assets/fonts/ABCWhyte-Bold.woff2") format("woff2"),
    url("assets/fonts/ABCWhyte-Bold.woff") format("woff"),
    url("assets/fonts/ABCWhyte-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ABCWhyte Regular";
  src:
    url("assets/fonts/ABCWhyte-Regular.woff2") format("woff2"),
    url("assets/fonts/ABCWhyte-Regular.woff") format("woff"),
    url("assets/fonts/ABCWhyte-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
